import type { NextPage } from "next";

import Container from "../components/layout/Container";
import Main from "../components/layout/Main";

const NotFound: NextPage = () => {
  return (
    <Main>
      <Container>
        <p className="text-xl font-medium mb-3 mt-12">404</p>
        <p>Page not found</p>
      </Container>
    </Main>
  );
};

export default NotFound;
